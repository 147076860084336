import api from "../../axios/axios";
import { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import { Redirect } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";

export default function EditTownPage(props) {
  const [town, setTown] = useState({});
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    async function getTown() {
      let savedTown = await api
        .getTownByID(props.match.params.id)
        .then((res) => res.data);
      setTown(savedTown);
    }
    getTown();
  }, []);

  function handleChange(event) {
    let newTown = { ...town };
    newTown["details"][event.target.name] = event.target.value;
    return setTown(newTown);
  }

  function handleSubmit(event) {
    event.preventDefault();
    return api
      .patchTowns(props.match.params.id, town)
      .then((result) => setRedirect(true));
  }

  if (!town.details) {
    return <div>Loading</div>;
  }
  if (redirect) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="townEditPage">
      <Navbar />
      <div className="townPage">
        <div className="townSpace">
          <form onSubmit={handleSubmit}>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Edit Town Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="name">Town Name</label>
                  </td>
                  <td>
                    <input
                      name="name"
                      onChange={handleChange}
                      type="text"
                      value={town.details.name}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="government">Town Leadership</label>
                  </td>
                  <td>
                    <textarea
                      name="government"
                      onChange={handleChange}
                      cols="30"
                      rows="4"
                      value={town.details.government}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="notablePlaces">Interesting Places</label>
                  </td>
                  <td>
                    <textarea
                      name="notablePlaces"
                      onChange={handleChange}
                      cols="30"
                      rows="4"
                      value={town.details.notablePlaces}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <button>Submit Changes</button>
          </form>
        </div>
      </div>
    </div>
  );
}
