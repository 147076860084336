import React, { useState } from "react";
import api from "../../axios/axios";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import PropTypes from "prop-types";

function UserProfileUpdateForm(props) {
  const [formData, setFormData] = useState(props.user);

  const id = props.user._id;

  const handleChange = (e) => {
    let newData = { ...formData };

    newData[e.target.id] = e.target.value;

    setFormData(newData);
  };

  //function to update user
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      await api.updateUser(formData);
    } catch (err) {
      console.log(err);
    }
  };

  //function to delete user
  const handleDeleteUser = async () => {
    try {
      await api.deleteUser(id);
      props.logoutUser();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="userProfileForm">
      <form action="" onSubmit={handleUpdateUser}>
        <Input addonBefore="Username" id="name" onChange={handleChange} />
        <Input addonBefore="About" id="about" onChange={handleChange} />
        <Button type="primary" htmlType="submit">
          Update Account
        </Button>
      </form>
      <Button
        type="primary"
        htmlType="submit"
        danger
        on
        onClick={handleDeleteUser}
      >
        Delete account
      </Button>
    </div>
  );
}

UserProfileUpdateForm.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(UserProfileUpdateForm);
