import "./TownsPeople.css";

function TownsPeople(props) {
  return props.people.map((person) => (
    <details className="person" key={`person-${person.Name}${person.lastName}`}>
      <summary>
        {person.Name} {person.lastName}
      </summary>
      <div className="personDetails">
        Race: {person.race}
        <br />
        Gender: {person.gender}
        <br />
        Traits: {person.traits}
        <br />
        Desires: {person.desires}
      </div>
    </details>
  ));
}

export default TownsPeople;
