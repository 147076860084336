import axios from "axios";

class DataService {
  constructor(url = "/api", client = axios.create()) {
    this.url = url;
    this.client = client;
  }
  //axios for towns
  uploadTown(townData) {
    return this.client.post(this.url + "/towns", townData);
  }

  getTowns(user) {
    return this.client.get(this.url + "/towns/" + user);
  }

  getTownByID(Id) {
    return this.client.get(this.url + "/towns/fluffernutter/" + Id);
  }

  patchTowns(townId, townData) {
    return this.client.patch(this.url + "/towns/" + townId, townData);
  }

  deleteTown(townID) {
    return this.client.delete(this.url + "/towns/" + townID);
  }
  //axios for user
  // getUser(userData) {
  //   return this.client.get(this.url + "/user");
  // }

  getUserById(id) {
    return this.client.get(this.url + "/user/" + id);
  }

  //patch user
  updateUser(userData) {
    return this.client.patch(this.url + "/user/" + userData._id, userData);
  }

  //delete user
  deleteUser(userID) {
    return this.client.delete(this.url + "/user/" + userID);
  }
}

export default new DataService();
