import NPCs from "../NPCs.json";
import details from "../details.json";
import townDetails from "../townData.json";

function characterGeneration(num) {
  let user = localStorage.getItem("userEmail");
  let npcs = [];
  const raceArray = [
    "dwarf",
    "human",
    "elf",
    "gnome",
    "dragonborn",
    "halfOrc",
    "halfling",
    "tiefling",
  ];

  for (let i = 0; i < num; i++) {
    const race = raceArray[Math.floor(Math.random() * raceArray.length)];
    const gender = Math.floor(Math.random() * 2) === 1 ? "male" : "female";
    let lastName = "";
    if (NPCs[race]["last"]) {
      lastName =
        NPCs[race]["last"][
          Math.floor(Math.random() * NPCs[race]["last"].length)
        ];
    }
    let npc = {
      Name:
        NPCs[race][gender][
          Math.floor(Math.random() * NPCs[race][gender].length)
        ],
      lastName: lastName,
      race: race,
      gender: gender,
      traits: details.traits[Math.floor(Math.random() * details.traits.length)],
      desires:
        details.desires[Math.floor(Math.random() * details.desires.length)],
    };
    npcs.push(npc);
  }
  let town = {
    details: {
      name:
        townDetails.name[Math.floor(Math.random() * townDetails.name.length)],
      government:
        townDetails.government[
          Math.floor(Math.random() * townDetails.government.length)
        ],
      notablePlaces:
        townDetails.notablePlaces[
          Math.floor(Math.random() * townDetails.notablePlaces.length)
        ],
    },
    population: npcs,
    user: user,
  };
  return town;
}

export default characterGeneration;
