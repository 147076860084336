import Login from "../../components/login/Login";
import Register from "../../components/register/Register";
import Header from "../../components/header/Header";

function LoginPage() {
  return (
    <>
      <div className="loginWrapper">
        <Header />
      </div>
      <div className="loginDIv">
        <div className="loginBlock">
          <Login />
        </div>
        <div className="registerBlock">
          <Register />
        </div>
      </div>
    </>
  );
}

export default LoginPage;
