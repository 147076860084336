import React, { useState } from "react";
import { Modal, Button } from "antd";
import UserProfileUpdateForm from "../userProfileUpdateForm/UserProfileUpdateForm";

const ProfileUpdateModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    window.location.reload(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Update Information
      </Button>
      <Modal
        title="Update"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <UserProfileUpdateForm {...props} />
      </Modal>
    </>
  );
};

export default ProfileUpdateModal;
