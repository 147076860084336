import React, { useState, useEffect } from "react";
import api from "../../axios/axios";
import ProfileUpdateModal from "../profileUpdateModal/ProfileUpdateModal";

function UserProfile() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await api.getUserById(userId).then((res) => res.data);
      setUser(response);
    } catch (err) {
      console.error(err);
    }
  };
  let userAbout = "";

  if (user) {
    if (user.about) {
      userAbout = `About Me: ${user.about}`;
    }
  }

  if (!user) {
    return <div>Loading profile!</div>;
  }
  return (
    <div>
      <div className="userProfileDiv">
        <h1 className="derp">{user.name}'s Towns.</h1>
        <ProfileUpdateModal user={user} />
      </div>
      <h3>{userAbout}</h3>
    </div>
  );
}

export default UserProfile;
