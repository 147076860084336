import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../axios/axios";
import "./SavedTowns.css";

function SavedTowns() {
  const [Towns, setTowns] = useState([]);
  let user = localStorage.getItem("userEmail");

  useEffect(() => {
    async function getTowns(user) {
      try {
        return api.getTowns(user).then((res) => setTowns(res.data));
      } catch (err) {
        console.error(err);
      }
    }
    getTowns(user);
  }, [user]);

  let townsLinks = Towns.map((town) => (
    <Link className="link" key={town._id} to={`/savedtown/${town._id}`}>
      {town.details.name}
    </Link>
  ));

  return townsLinks;
}

export default SavedTowns;
