import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Town from "../../components/town/Town";
import api from "../../axios/axios";
import Navbar from "../../components/navbar/Navbar";

function SavedTownPage(props) {
  const [town, setTown] = useState({});
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    async function getTown(id) {
      let savedTown = await api.getTownByID(id).then((res) => res.data);
      setTown(savedTown);
    }
    getTown(props.match.params.id);
  }, [props.match.params.id]);

  function deleteTown() {
    return api
      .deleteTown(props.match.params.id)
      .then((result) => setRedirect(true));
  }

  if (!town.details) {
    return (
      <div className="savedTownPage">
        <Navbar />
        <div className="townPage">
          <div className="townSpace">Town is loading...</div>
        </div>
      </div>
    );
  }
  if (redirect) {
    return <Redirect to="/profile" />;
  }
  return (
    <div className="savedTownPage">
      <Navbar />
      <div className="townPage">
        <button onClick={deleteTown}>Delete Town</button>
        <Link to={`/edit-town/${town._id}`}>
          <button>Edit Town</button>
        </Link>
        <div className="townSpace">
          <Town
            townName={town.details.name}
            government={town.details.government}
            notablePlaces={town.details.notablePlaces}
            population={town.population}
          />
        </div>
      </div>
    </div>
  );
}

export default SavedTownPage;
