import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import Town from "../../components/town/Town";
import characterGeneration from "../../utils/characterGeneration";
import "./TownPage.css";
import api from "../../axios/axios";
import Navbar from "../../components/navbar/Navbar";

function TownPage() {
  const [town, setTown] = useState({});
  const [reroll, setReroll] = useState(false);

  useEffect(() => {
    let newTown = characterGeneration(10);
    setTown(newTown);
  }, [reroll]);

  function submitTown(townData) {
    api.uploadTown(townData).then(console.log("sent"));
    return setReroll(!reroll);
  }

  function rerollTown() {
    return setReroll(!reroll);
  }

  if (!town.details) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="townPage">
        <div className="buttonSpace">
          <button onClick={() => rerollTown()}>New Town</button>
          <button onClick={() => submitTown(town)}>Save Town</button>
        </div>
        <div className="townSpace">
          <Town
            townName={town.details.name}
            government={town.details.government}
            notablePlaces={town.details.notablePlaces}
            population={town.population}
          />
        </div>
      </div>
    </div>
  );
}

export default TownPage;
