import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "./404.css";

function NotFoundPage() {
  return (
    <div>
      <Navbar />
      <div className="missingPage">
        <h1>YOU SHALL NOT PASS!!!! No, but really, you shouldn't be here...</h1>
        <h1>Not all who wander are lost...but you sure are</h1>
        <h1>
          Did you hear that the scientists at Jurassic world are having issues
          getting urine samples from the pterodactyls? Its cause the P is silent
        </h1>
        <img src="https://i.imgflip.com/4uac4l.jpg" alt="Chads regret" />
      </div>
    </div>
  );
}

export default NotFoundPage;
