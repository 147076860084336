import React from "react";
import UserProfile from "../../components/userProfile/UserProfile";
import SavedTowns from "../../components/savedTowns/SavedTowns";
import { Layout } from "antd";
import Navbar from "../../components/navbar/Navbar";
import image from "../../assets/page-background.jpg";

const { Header, Footer, Sider, Content } = Layout;

function ProfilePage() {
  return (
    <div>
      <Layout>
        <Header
          style={{ backgroundImage: `url(${image})`, marginBottom: "20px" }}
        >
          <Navbar />
        </Header>
        <Layout>
          <Content style={{ padding: "0 100px", margin: "0 auto" }}>
            <UserProfile />
            <div className="savedTownsDiv">
              <SavedTowns />
            </div>
          </Content>
        </Layout>
        <Footer></Footer>
      </Layout>
    </div>
  );
}

export default ProfilePage;
