import "./App.css";
import LoginPage from "../src/pages/loginPage/LoginPage";
import { Switch, Route } from "react-router-dom";
import ProfilePage from "./pages/profilePage/ProfilePage";
import TownPage from "./pages/townPage/TownPage";
import EditTownPage from "./pages/editTownPage/EditTownPage";
import SavedTownPage from "./pages/savedTownPage/SavedTownPage";
import NotFoundPage from "./pages/404Page/404";
import PrivateRoute from "./components/private-route/PrivateRoute";
import jwt_decode from "jwt-decode";
import setAuthToken from "./redux/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "../src/redux/actions/authActions";
import { Provider } from "react-redux";
import store from "./redux/store";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);

  const decoded = jwt_decode(token);

  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());

    window.location.href = "./";
  }
}

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <PrivateRoute exact path="/town" component={TownPage} />
          <PrivateRoute exact path="/savedtown/:id" component={SavedTownPage} />
          <PrivateRoute exact path="/edit-town/:id" component={EditTownPage} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <Route exact path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
