import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import { Redirect } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import image from "../../assets/page-background.jpg";

const { Header } = Layout;

const Navbar = (props) => {
  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  if (!props.auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="navbar">
      <h1>Denizens & Districts</h1>
      <Menu
        style={{
          backgroundImage: `url(${image})`,
          color: "#5a5a5a",
          fontSize: "1.5rem",
        }}
        mode="horizontal"
      >
        <Menu.Item key="1" style={{ color: "#5a5a5a" }}>
          {" "}
          <Link className="link" to="/town">
            Town
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link className="link" to="/profile">
            Profile
          </Link>
        </Menu.Item>
        <Button onClick={onLogoutClick} style={{ fontSize: "15px" }}>
          Logout
        </Button>
      </Menu>
    </div>
  );
};

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Navbar);
