import React from "react";
import TownsPeople from "../townsPeople/TownsPeople";
import "./Town.css";

function Town(props) {
  return (
    <div className="town">
      <div className="townDetails">
        Town Name: <p>{props.townName}</p>
        Ruled by: <p>{props.government}</p>
        Place(s) of Interest:<p>{props.notablePlaces}</p>
      </div>
      <div className="population">
        <div className="peopleLoader">
          <TownsPeople people={props.population} />
        </div>
      </div>
    </div>
  );
}

export default Town;
